import React from "react"
import Layout from "../components/Layout"
import big from "../assets/images/bgmain.jpg"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

//para espacio de spacer hacer pequeno el background
const spacer = 20

//para agrandamiento de segundo porcentaje de background
const percentage = 200

const Franja = styled.div`
  background: black;
  height: 60px;
`

const TituloBox = styled.div`
  font-size: 38px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  color: white;
`
const MensajeBox = styled.div`
  font-size: 58px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`

const LeftBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`

const RightBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  /* letter-spacing: -2px; */
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`
const Boldie = styled.div`
  font-weight: bold;
`

const Moviles = () => {
  return (
    <Layout sbiggo={big} spacer={spacer} percentage={percentage}>
      <Franja>
        <Container>
          <Row>
            <Col>
              <TituloBox>Aplicaciones Móviles</TituloBox>
            </Col>
          </Row>
        </Container>
      </Franja>
      <Container>
        <Row>
          <Col>
            <MensajeBox>
              Creamos aplicaciones móviles IOS y Android con tecnologías
              híbridas
            </MensajeBox>
          </Col>
        </Row>
      </Container>
      <StaticImage
        src="../assets/images/moviles.jpg"
        alt="web"
        aspectRatio={26 / 9}
      />
      <Container>
        <Row>
          <Col md={3} className="d-none d-lg-block">
            <LeftBox>
              <Boldie>Contáctenos: </Boldie>
              <br /> info@yaku.com.ec <br />
              +593999944405
            </LeftBox>
          </Col>
          <Col md={9} xs={12} className="d-lg-block">
            <RightBox>
              En Yaku proporcionamos asesoría y puesta en marcha de proyectos de
              aplicaciones móviles. Transformamos su sitio web en una versión
              móvil multifuncional o creamos una aplicación desde cero.
              <br /> <br />
              Mediante el uso de tecnologías de alto desempeño como React Native
              o Flutter creamos aplicaciones móviles listas para uso tanto en la
              tiendas de Apple como de Android. <br /> <br />
              Gracias a las nuevas tecnologías móviles híbridas ya no es
              necesario elevar su presupuesto para crear versiones distintas
              para cada tienda de aplicaciones. <br /> <br />
              El uso de un SOLO código permite adaptar su app móvil tanto para
              dispositivos IOS o Android. Nuestras aplicaciones móviles cuantan
              con diseño personalizado, uso de código abierto, uso de
              tecnologías remotas de acceso y modificación de la información.
              <br /> <br />
              Nos encargamos de realizar todo el proceso desde la creación de la
              app móvil hasta su publicación en las tiendas de aplicacioes IOS o
              Android.
              <br /> <br />
              <br />
              <Boldie>Experiencia</Boldie>
              <br /> <br />
              Hemos creado soluciones para empresas líderes del sector privado.
              También trabajamos con proyectos del sector público. Con mucho
              gusto le podemos hacer llegar nuestro portafolio de proyectos
              creados. Contáctenos pronto!
              <br /> <br />
              Mantenemos una amplia cartera de clientes que han confiado en
              nuestro trabajo. Hemos creado sitios web, aplicaciones web,
              aplicaciones sociales, entre otros. <br />
              <br />A través de los años hemos incrementado el uso de nuevas
              tecnologías que le permitan a nuestros clientes poder solventar
              cualquier requerimiento sin importar el grado de complejidad.
              <br />
              <br />
              ¿Tiene un nuevo proyecto? Permítanos ayudarle a conseguir los
              objetivos planificados. Nuestro soporte es personalizado, somos
              parte de su equipo y nos comprometemos a obtener las metas de cada
              proyecto. <br />
              <br />
              <br />
            </RightBox>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Moviles
